export const defaults = {
    page_title: "Сетка ТВ - Телеграм.SITES",
    main_url: "https://t.me/PoliTube_news",
    prosper_tracker_suffix: "",
    name: "Сетка ТВ",
    tag: "@PoliTube_news",
    main_avatar_url: "/img/setka.png",
    parsers:[
      {
        name: "Pasha TODAY",
        parser: "PASHA_TODAY",
        tag: "@pashatoday_new",
        main_url: "https://t.me/pashatoday_new",
        avatar_url: "/img/pasha.jpg",
        contacts_phrase: "Посмотри, Бугагагагаг//// Бугагага",
        new_messages: 8
      },
      {
        name: "Тарик Незалежко",
        parser: "TARIK",
        tag: "@tarik_nezalejko",
        main_url: "https://t.me/tarik_nezalejko",
        avatar_url: "/img/tarik.jpg",
        contacts_phrase: "Украина без Зе...",
        new_messages: 5
      },
      {
        name: "Politube News",
        parser: "POLITUBE_NEWS",
        tag: "@PoliTube_news",
        main_url: "https://t.me/PoliTube_news",
        avatar_url: "/img/politube.jpg",
        contacts_phrase: "Украина лишилась за последние...",
        new_messages: 3
      },

    ],
    posts: []
  }
